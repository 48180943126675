import { Singleton } from 'utils/singleton';
import { action, observable } from 'mobx';
import MobxFormStore from 'utils/MobxForm';
import { required } from 'utils/MobxForm/validators';

/**
 * Ключ для хранения значения завершенности опроса в localStorage.
 */
const IS_QUESTIONS_SUBMITTED = 'is_questions_submitted';

export class QuestionsStore extends Singleton {
  /**
   * Завершен ли опрос.
   */
  @observable
  public isQuestionsSubmitted = !!localStorage.getItem(IS_QUESTIONS_SUBMITTED);

  @observable
  public form = new MobxFormStore({
    fields: [
      {
        label: 'Вы брали ипотеку в 2024?',
        name: 'question-1',
        type: 'radio',
        validators: [required],
      },
      {
        label: 'Общая сумма ваших кредитных обязательств превышает 25 000 рублей?',
        name: 'question-2',
        type: 'radio',
        validators: [required],
      },
      {
        label: 'В январе-июле 2024 года вы заменяли номер телефона для входа в Личный кабинет?',
        name: 'question-3',
        type: 'radio',
        validators: [required],
      },
    ],
  });

  @action
  public setIsQuestionsSubmitted(value: boolean) {
    this.isQuestionsSubmitted = value;

    if (value) {
      localStorage.setItem(IS_QUESTIONS_SUBMITTED, 'true');
    } else {
      localStorage.removeItem(IS_QUESTIONS_SUBMITTED);
    }
  }
}
